import React, { useEffect } from "react";
import logo from "../assets/header/itribe-logo.svg";
import "./Navbar.css";
import fb from "../assets/footer/fb.svg";
import linkedin from "../assets/footer/linkedin.svg";
import youtube from "../assets/footer/youtube.svg";
import twitter from "../assets/footer/twitter.svg";
import insta from "../assets/footer/insta.svg";
import telegram from "../assets/footer/telegram.svg";

const Navbar = () => {
  // Hamburger Logic
  useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector("#navbar-items");
    const sidebar = document.querySelector("#sidebar");
    const body = document.querySelector("#body");
    hamburger.addEventListener("click", mobileMenu);

    function mobileMenu() {
      hamburger.classList.toggle("active");
      sidebar.classList.toggle("active");
      body.classList.toggle("scroll-lock");
      navMenu.classList.toggle("hide");
    }
    const navLink = document.querySelectorAll(".nav-link");
    navLink.forEach((n) => n.addEventListener("click", closeMenu));

    function closeMenu() {
      hamburger.classList.remove("active");
      sidebar.classList.remove("active");
      body.classList.remove("scroll-lock");
      navMenu.classList.remove("hide");
    }
  }, []);

  return (
    <div id="navbar">
      <div>
        <a href="./#">
          <img src={logo} className="logo" alt="logo" />
        </a>
      </div>
      <div id="navbar-items">
        <a href="./#learn-sec">
          {" "}
          <p>Learn</p>
        </a>
        <a href="./#game-sec">
          {" "}
          <p>Game</p>
        </a>
        <a href="./#feature-sec">
          {" "}
          <p>Features</p>
        </a>
        <a href="./#team-sec">
          {" "}
          <p>Team</p>
        </a>
        <a href="./#investor-sec">
          {" "}
          <p>Investors</p>
        </a>
        <a href="./#feedback-sec">
          {" "}
          <p>Contact us</p>
        </a>
      </div>
      <div id="sidebar">
        <div className="navigators">
          <h3> MENU</h3>
          <a className="nav-link" href="./#learn-sec">
            {" "}
            <p id="nav-p">Learn</p>
          </a>
          <a className="nav-link" href="./#game-sec">
            {" "}
            <p id="nav-p">Game</p>
          </a>
          <a className="nav-link" href="./#feature-sec">
            {" "}
            <p id="nav-p">Features</p>
          </a>
          <a className="nav-link" href="./#team-sec">
            {" "}
            <p id="nav-p">Team</p>
          </a>
          <a className="nav-link" href="./#investor-sec">
            {" "}
            <p id="nav-p">Investors</p>
          </a>
          <a className="nav-link" href="./#feedback-sec">
            {" "}
            <p id="nav-p">Contact us</p>
          </a>
        </div>
        <div id="sidebar-footer">
          <div className="sidebar-row-1">
            <img src={logo} id="sidebar-logo" alt="logo" />
            <hr className="solid" />
          </div>
          <div className="sidebar-social-icons-row">
            <a
              href="https://www.instagram.com/itribe_official/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} className="sidebar-footer-icon" alt="insta" />
            </a>
            <a
              href="https://www.linkedin.com/company/investorstribe/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedin}
                className="sidebar-footer-icon"
                alt="linkedin"
              />
            </a>

            <a
              href="https://twitter.com/itribe_"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={twitter}
                className="sidebar-footer-icon"
                alt="twitter"
              />
            </a>
          </div>
          <div className="sidebar-social-icons-row">
            <a
              href="https://www.youtube.com/channel/UCF7bd0Xzp3D2sSukBtjQGhQ"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={youtube}
                className="sidebar-footer-icon"
                alt="youtube"
              />
            </a>
            <a
              href="https://www.facebook.com/InvestorsTribe/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} className="sidebar-footer-icon" alt="fb" />
            </a>
            <a href="https://t.me/+w3OuB-Purt0yNjll">
              <img
                src={telegram}
                className="sidebar-footer-icon"
                alt="telegram"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="hamburger">
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </div>
  );
};

export default Navbar;
